<template>
  <div class="nj-grid">
    <div class="header">
      <div class="title">
        <v-icon>mdi-shield-account-outline</v-icon>
        {{ $route.meta.title || $route.name }}
      </div>
      <v-btn
        icon
        title="Retornar"
        class="align-self-baseline"
        :to="{ name: 'TaskSchedulers' }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <div class="ng-grid-body">
      <TaskScheulerForm refs="form">
        <div class="form-actions">
          <v-btn class="cancel" elevation="0" @click.prevent="$router.go(-1)">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn elevation="0" type="submit" color="primary">
            <v-icon>mdi-content-save</v-icon>
            Salvar
          </v-btn>
        </div>
      </TaskScheulerForm>
    </div>
  </div>
</template>
<script>
import TaskScheulerForm from './form'

export default {
  name: 'TaskSchedulerEdit',
  components: { TaskScheulerForm },
}
</script>
